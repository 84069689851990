<template>
    <div>
        dashboard
    </div>
  </template>

<script>

export default {
    name: "DashboardParceiro"
}

</script>
